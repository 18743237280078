<template>
  <div class="transaction-list">
    <div class="container-xl">
      <div class="card card-page">
        <div class="card-header">
          <div class="card-title">История платежей</div>
        </div>
        <div class="card-body position-relative block-statement">
          <data-loading :loading="statement.loading" :lock="true">
            <i>Создание выписки...</i>
          </data-loading>
          <div class="clearfix mb-3">
            <div class="h6 float-left mt-2 mb-0">Заказать выписку</div>
            <button class="btn btn-sm btn-outline-info float-right d-sm-none"
                    type="button"
                    data-toggle="collapse"
                    data-target="#list-statement"
                    aria-expanded="false"
            >
              <i class="bi-filter"></i>
            </button>
          </div>
          <form @submit.stop.prevent="submitStatement" class="filter-form collapse d-sm-block" id="list-statement">
            <div class="row">
              <div class="col-12 col-sm-8 col-md-6 col-lg-5">
                <calendar-range
                    :initial-start="statement.dateRange.start"
                    :initial-end="statement.dateRange.end"
                    @update="handleStatementDateRange"
                />
              </div>
              <div class="col-12 col-sm-3 col-md-2 mt-2 mt-sm-0 text-right text-sm-left">
                <button type="submit" class="btn btn-outline-success">
                  Заказать
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="card-body">
          <div class="clearfix mb-3">
            <div class="h6 float-left mt-2 mb-0">История платежей</div>
            <button class="btn btn-sm btn-outline-info float-right d-md-none"
                    type="button"
                    data-toggle="collapse"
                    data-target="#list-filter"
                    aria-expanded="false"
            >
              <i class="bi-filter"></i>
            </button>
          </div>
          <paginated-list :filter="filter"
                          :count="collection.count"
                          :total-count="collection.totalCount"
                          :data-loading="collection.loading"
                          :data-loading-lock="true"
                          @update="loadData"
          >
            <template v-slot:filter>
              <div class="filter-form mb-3 collapse d-md-block" id="list-filter">
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4" style="padding-right: 5px;">
                    <calendar-range
                        :initial-start="filter.dateRange.start"
                        :initial-end="filter.dateRange.end"
                        @update="handleFilterDateRange"
                    />
                  </div>
                  <div class="col-12 col-md-6 col-lg-2 mt-2 mt-md-0">
                    <c-multi-select :model="filter.paymentTypes"
                                    :options="paymentTypeOptions"
                                    placeholder="Тип"
                                    @input="paymentTypeInput"/>
                  </div>
                  <div class="col-12 col-md-9 col-lg-4 mt-2 mt-lg-0">
                    <c-multi-select :model="filter.transactionTypes"
                                    :options="transactionTypeOptions"
                                    placeholder="Пояснение"/>
                  </div>
                  <div class="col-12 col-md-3 col-lg-2 mt-2 mt-lg-0 text-right text-lg-left">
                    <button type="submit" class="btn btn-outline-success">
                      Фильтровать
                    </button>
                  </div>
                </div>
              </div>
            </template>
            <div v-if="collection.count > 0">
              <div class="row d-none d-md-flex">
                <div class="col-2 small text-muted">Дата</div>
                <div class="col-2 small text-muted">Сумма</div>
                <div class="col-1 small text-muted text-center">Тип</div>
                <div class="col-5 small text-muted">Пояснение</div>
              </div>
              <div v-for="item in collection.items"
                   :class="{ 'text-info': isPending(item.status), 'text-danger': isCanceled(item.status) }"
                   class="row mt-3 mb-3">
                <div class="col-5 col-md-2">
                  <date-format :date="item.date"/>
                </div>
                <div class="col-5 col-md-2">
                  <money :money="item.money"/>
                </div>
                <div class="col-2 col-md-1 text-right text-md-center col-arrow">
                  <i v-if="isCredit(item.paymentType)" class="bi-arrow-left text-success" title="Входящий"></i>
                  <i v-if="isDebit(item.paymentType)" class="bi-arrow-right" title="Исходящий"></i>
                </div>
                <div class="col-9 col-md-5">
                  {{ transactionTypePurpose(item.transactionType) }}
                </div>
                <div class="col-3 col-md-2 text-right">
                  <router-link :to="{ name: 'transactionDetails', params: { uuid: item.uuid } }"
                               class="btn btn-outline-info btn-sm"
                  >Детали
                  </router-link>
                </div>
              </div>
            </div>
          </paginated-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CMultiSelect, DataCollection, DataLoading, DateFormat, Money, PaginatedList } from "@/components/common";
import { apiClientConfig } from "@/configs";
import { PaymentType, TransactionStatus } from "@/utils/api-client";
import { createTransactionProvider } from "@/utils/di";
import { DatePicker } from "v-calendar";
import { borrowerPaymentTransactionTypes, commonPaymentTransactionTypes, lenderPaymentTransactionTypes } from "../data/paymentTransactionTypes";
import paymentTypeLabels from "../data/paymentTypeLabels";
import transactionTypePurposes from "../data/transactionTypePurposes";
import CalendarRange from "@/components/common/components/CalendarRange.vue";

export default {
  name: "TransactionList",
  components: {
    CalendarRange,
    CMultiSelect,
    DataLoading,
    DateFormat,
    DatePicker,
    Money,
    PaginatedList
  },
  inject: ["profile"],
  data() {
    return {
      statement: {
        dateRange: {
          start: null,
          end: null
        },
        loading: false
      },
      filter: {
        dateRange: {
          start: null,
          end: null
        },
        paymentTypes: [],
        transactionTypes: []
      },
      collection: new DataCollection()
    };
  },
  computed: {
    paymentTypeOptions() {
      return Object.keys(paymentTypeLabels).map((value) => ({ value, text: paymentTypeLabels[value] }));
    },
    transactionTypeOptions() {          
      const types = this.getPaymentTransactionTypes(this.filter.paymentTypes);
      return types.map((value) => ({ value, text: transactionTypePurposes[value] }));
    }
  },
  methods: {
    async loadData(page, perPage, filter) {
      const { dateRange, paymentTypes, transactionTypes } = filter;
      this.collection.startLoading();
      try {
        const provider = await createTransactionProvider();
        const collection = await provider.getList(
            { dateFrom: dateRange.start, dateTo: dateRange.end, paymentTypes, transactionTypes },
            page,
            perPage
        );
        this.collection.setCollection(collection);
      } finally {
        this.collection.finishLoading();
      }
    },
    isCredit(paymentType) {
      return paymentType === PaymentType.CREDIT;
    },
    isDebit(paymentType) {
      return paymentType === PaymentType.DEBIT;
    },
    isPending(status) {
      return status === TransactionStatus.PENDING;
    },
    isCanceled(status) {
      return status === TransactionStatus.CANCELED;
    },
    transactionTypePurpose(transactionTypes) {
      return transactionTypePurposes[transactionTypes] || transactionTypes;
    },
    handleStatementDateRange({ start, end, valid }) {
      if (valid) {
        this.statement.dateRange = {
          start: this.formatDate(start),
          end: this.formatDate(end)
        };
      }
    },
    formatDate({ day, month, year }) {
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    handleFilterDateRange({ start, end, valid }) {
      if (valid) {
        this.filter.dateRange = {
          start: this.formatDate(start),
          end: this.formatDate(end)
        };
      }
    },
    paymentTypeInput(paymentTypes) {
      const types = this.getPaymentTransactionTypes(paymentTypes);
      this.filter.transactionTypes = this.filter.transactionTypes.filter((type) => types.indexOf(type) > -1);
    },
    async submitStatement() {
      this.statement.loading = true;
      try {
        const dateRange = this.statement.dateRange;
        const provider = await createTransactionProvider();
        const urlResult = await provider.exportStatement({ dateFrom: dateRange.start, dateTo: dateRange.end });
        window.location.assign(apiClientConfig.baseUrl + urlResult.url);
      } finally {
        this.statement.loading = false;
      }
    },
    getPaymentTransactionTypes(paymentTypes) {
      let creditPaymentTransactionTypes = [...commonPaymentTransactionTypes[PaymentType.CREDIT]];
      let debitPaymentTransactionTypes = [...commonPaymentTransactionTypes[PaymentType.DEBIT]];
      if (this.profile.isBorrower) {
        creditPaymentTransactionTypes = [...creditPaymentTransactionTypes, ...borrowerPaymentTransactionTypes[PaymentType.CREDIT]];
        debitPaymentTransactionTypes = [...debitPaymentTransactionTypes, ...borrowerPaymentTransactionTypes[PaymentType.DEBIT]];
      }
      if (this.profile.isLender) {
        creditPaymentTransactionTypes = [...creditPaymentTransactionTypes, ...lenderPaymentTransactionTypes[PaymentType.CREDIT]];
        debitPaymentTransactionTypes = [...debitPaymentTransactionTypes, ...lenderPaymentTransactionTypes[PaymentType.DEBIT]];
      }
      let types = [];
      if (paymentTypes.length === 0 || paymentTypes.indexOf(PaymentType.CREDIT) > -1) {
        types = [...types, ...creditPaymentTransactionTypes];
      }
      if (paymentTypes.length === 0 || paymentTypes.indexOf(PaymentType.DEBIT) > -1) {
        types = [...types, ...debitPaymentTransactionTypes];
      }
      return types;
    }
  }
};
</script>

<style lang="scss">
.transaction-list {
  .card {
    .h6 {
      margin-bottom: 1rem;
    }

    .col-arrow {
      font-size: 1.2rem;
      line-height: 0.9;
    }

    .paginator {
      margin-top: 2rem;
    }
  }

  .block-statement {
    .data-loading {
      .data-loading-lock {
        .data-loading-message {
          margin-top: 3rem;
        }
      }
    }
  }
}
</style>
